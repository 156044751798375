export const environment = {
  production: false,
  KENDO_UI_LICENCE:
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkxJQyJ9.eyJwcm9kdWN0cyI6W3sidHJpYWwiOmZhbHNlLCJjb2RlIjoiS0VORE9VSVJFQUNUIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNzIwNDIyOTg1fSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlDT01QTEVURSIsImxpY2Vuc2VFeHBpcmF0aW9uRGF0ZSI6MTcyMDQyMjk4NX0seyJ0cmlhbCI6ZmFsc2UsImNvZGUiOiJLRU5ET1VJVlVFIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNzIwNDIyOTg1fSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlBTkdVTEFSIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNzIwNDIyOTg1fV0sImludGVncml0eSI6Ims1Q1JMUU1EU3p4T29mRUhKQ3dtUlhrMnoyZz0iLCJsaWNlbnNlSG9sZGVyIjoieWF3QHRyaWFycWhlYWx0aC5jb20iLCJpYXQiOjE2OTUyMTM1MjUsImF1ZCI6Im1pbGluZEB0cmlhcnFoZWFsdGguY29tIiwidXNlcklkIjoiNjFiNzJmODktNGMzOC00NDkzLWE5NjctZGJkNTNlNmY4NGI1In0.PBsTV1ltuCqTquzFiBmr87C_jrFUV9TTWRhCHfNDwubQ2XXVKCL1ZUnsoA_7RhBbEAwjv1_Thwgb7ZRAcFXwMJqySu_Dn9IjRg-nAP32ZMBbzl7d2g5BZmymVnlW8mAim8NEn2-KqinkzV_kIb--w4JtGvQAIHy7fetjuD_sgh8_teoOnTLfmr-Ee95X8SZV9eTBP9G9DVLsfOCUTYYZUidisNWJY5W_6VNMTDvkWM_K1i_QsLooEMH8sq8B3iUh9BeAtXSYMmxT4Cw_zr6wbbTZH66V6HTohVjnG2YCCoND9gHw_FnlXC9M63pnsdXoqxKFn_1pdOCMxPfjw72xZw',
  host: 'https://pact-dev.myqone.com/',
  secretKey: 'cUuv+sAjV4vzs5r+hfobOQ==',
  firebaseConfig: {
    apiKey: 'AIzaSyBT-VrBf3fWMKy0rF8pWmb4zCdOKeVc8F8',
    authDomain: 'pact-dev.myqone.com',
    projectId: 'bcbsm-dev',
    storageBucket: 'bcbsm-dev.appspot.com',
    messagingSenderId: '357683741982',
    appId: '1:357683741982:web:06ed55fd7f8e59640ab50f',   
    availityProvider: 'saml.pact-availity-angular',
    oktaProvider: 'saml.pact-okta-angular',
  },
};
